import React from "react";
import { useLocation } from "react-router-dom";

function sendPageView(location) {
  window.gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: location.pathname,
    send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  });
}

export function useGA() {
  const location = useLocation();

  React.useEffect(() => {
    if (
      window.location.hostname === "zimmerman.team" ||
      window.location.hostname === "www.zimmerman.team"
    ) {
      sendPageView(location);
    }
  }, [location.pathname]);

  return null;
}
