import React from "react";

export function AppbarLogo() {
  return (
    <svg width="27" height="35">
      <path
        d="M11.7928 2.97554C12.4622 1.69259 14.0657 1.22603 15.319 1.94957C16.5713 2.6726 16.9695 4.29296 16.195 5.5141L14.2941 8.51094C13.6069 9.59445 12.1875 9.94327 11.0763 9.30173C9.96599 8.66068 9.55802 7.25852 10.1511 6.12184L11.7928 2.97554Z"
        fill="url(#paint0_linear_17_7592)"
      />
      <circle
        cx="14.0417"
        cy="4.13458"
        r="2.29896"
        transform="rotate(30 14.0417 4.13458)"
        fill="white"
      />
      <path
        d="M14.8698 32.5201C14.2004 33.803 12.5969 34.2696 11.3436 33.546C10.0913 32.823 9.6931 31.2026 10.4676 29.9815L12.3685 26.9847C13.0557 25.9012 14.4751 25.5523 15.5863 26.1939C16.6966 26.8349 17.1046 28.2371 16.5115 29.3738L14.8698 32.5201Z"
        fill="url(#paint1_linear_17_7592)"
      />
      <circle
        cx="12.6209"
        cy="31.3654"
        r="2.29896"
        transform="rotate(-150 12.6209 31.3654)"
        fill="white"
      />
      <path
        d="M4.3264 2.96625C5.0067 1.68548 6.6136 1.22434 7.86954 1.94945C9.12473 2.67414 9.52922 4.29496 8.76171 5.52443L5.94665 10.0339C5.26862 11.12 3.85191 11.4735 2.74307 10.8333C1.63491 10.1935 1.23244 8.79109 1.83269 7.66102L4.3264 2.96625Z"
        fill="url(#paint2_linear_17_7592)"
      />
      <circle
        cx="6.59245"
        cy="4.13458"
        r="2.29896"
        transform="rotate(30 6.59245 4.13458)"
        fill="white"
      />
      <path
        d="M22.5374 32.5294C21.8571 33.8101 20.2502 34.2713 18.9942 33.5462C17.739 32.8215 17.3345 31.2006 18.1021 29.9712L20.9171 25.4617C21.5951 24.3756 23.0119 24.0221 24.1207 24.6623C25.2289 25.3021 25.6313 26.7045 25.0311 27.8346L22.5374 32.5294Z"
        fill="url(#paint3_linear_17_7592)"
      />
      <circle
        cx="20.2733"
        cy="31.3654"
        r="2.29896"
        transform="rotate(-150 20.2733 31.3654)"
        fill="white"
      />
      <path
        d="M19.2749 2.90687C20.0178 1.64893 21.6444 1.23881 22.895 1.99409C24.1453 2.74925 24.5395 4.37937 23.7724 5.62243L7.0088 32.7866C6.34134 33.8682 4.92719 34.2101 3.83927 33.5531C2.75154 32.8962 2.3959 31.4857 3.04212 30.3915L19.2749 2.90687Z"
        fill="url(#paint4_linear_17_7592)"
      />
      <circle
        cx="21.5719"
        cy="4.15984"
        r="2.29896"
        transform="rotate(31.13 21.5719 4.15984)"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17_7592"
          x1="14.9624"
          y1="2.73315"
          x2="11.1828"
          y2="9.2891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#807F7F" />
          <stop offset="1" stopColor="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17_7592"
          x1="11.7002"
          y1="32.7625"
          x2="15.4798"
          y2="26.2065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#807F7F" />
          <stop offset="1" stopColor="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17_7592"
          x1="7.42392"
          y1="2.88775"
          x2="2.85797"
          y2="10.8101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#807F7F" />
          <stop offset="1" stopColor="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17_7592"
          x1="19.4399"
          y1="32.6079"
          x2="24.0058"
          y2="24.6855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#807F7F" />
          <stop offset="1" stopColor="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17_7592"
          x1="21.043"
          y1="5.22311"
          x2="4.12914"
          y2="33.4023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDDDDD" />
          <stop offset="1" stopColor="#807F7F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
