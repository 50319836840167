import { ProjectProps } from "app/interfaces/portfolioProjects";
import tgfMain from "app/modules/Portfolio-module/assets/TGF main.webp";
import aidaMain from "app/modules/Portfolio-module/assets/AIDA main.webp";
import zoomMain from "app/modules/Portfolio-module/assets/Zoom main.webp";
import unescoMain from "app/modules/Portfolio-module/assets/UNESCO main.webp";
import iatiMain from "app/modules/Portfolio-module/assets/IATI.cloud main.webp";
import tgfSecondary from "app/modules/Portfolio-module/assets/TGF secondary.webp";
import openAidMain from "app/modules/Portfolio-module/assets/Openaid.fi main.webp";
import aidaSecondary from "app/modules/Portfolio-module/assets/AIDA secondary.webp";
import unescoSecondary from "app/modules/Portfolio-module/assets/UNESCO secondary.webp";
import unMigrationMain from "app/modules/Portfolio-module/assets/UN Migration main.webp";
import iatiSecondary from "app/modules/Portfolio-module/assets/IATI.cloud secondary.webp";
import openAidSecondary from "app/modules/Portfolio-module/assets/Openaid.fi secondary.webp";
import fcdoMain from "app/modules/Portfolio-module/assets/FCDO main.webp";
import fcdoSecondary from "app/modules/Portfolio-module/assets/FCDO secondary.webp";

export const portfolioProjectsData: ProjectProps[] = [
  {
    id: 1,
    year: "2021",
    slug: "Aida",
    result: "200K Users",
    categories: ["Data", "Portal Design"],
    client: "TODC",
    preview_image: aidaMain,
    gallery: [aidaSecondary],
    techniques: ["React.js", "Nivo charts", "Node.js"],
    design: [
      "Personas and User Stories",
      "UI/UX Design",
      "User Testing and Research",
    ],
    live_service: "https://aida.tools",
    link: "AIDA",
    title: "AIDA",
    introduction:
      "AIDA is the <a href='https://aida.tools' target='_blank'>Aid Information Data Analytics</a> data services platform for aid development professionals, who are interested in exploring, analysing and using open aid data sources, offered by 1500 organisations around the world, in an easy to use web application. It is managed by <a href='https://www.linkedin.com/company/the-open-data-compagny' target='_blank'>The Open Data Company B.V,</a>  a joint venture between Zimmerman and Data4Development that started its operations in 2021",
    the_challenge:
      "Creating an all-in-one SaaS (Software as a Service) data platform to allow aid development professionals to easily explore and analyse global data on development and humanitarian spending, as well as managing their programme and project information through the international  <a href='https://iatistandard.org/en/' target='_blank'>IATI data standard</a>  by the use of data management tools, that allows for easy creation and conversion of data to IATI compliant files, including mandatory ruleset enforced by some of their donors.",
    process:
      "Over 1500 organisations publish programme and project information in the international IATI data standard, including governments, multilaterals and NGOs. This amounts to data on over a million individual programmes and projects, which are aggregated on AIDA, allowing users to easily compare information as well as providing dashboard overviews of the development sector globally. Providing these types of dataviews allows for better understanding of international development spending, and is key to improving the efficiency of resource allocation, coordination of the response, as well as allowing for donors to learn from one another’s interventions. A key aspect of this is data quality, so the built in data management tool on AIDA allows organisations to publish their data more easily and accurately as well as validating their programme and project data against an international data standard for global compliance and interoperability.",
    solution:
      "AIDA provides an intuitive web interface to compare and explore development data globally, or on the level of individual countries, organisations or projects. Users are able to freely explore the application and drill down into the data, from the world map showing global commitments all the way down to individual programmes and projects. For specific queries, users are able to filter the data on all available IATI elements. The data manager allows organisations to publish their programmes and projects via bulk upload or simple forms for individual activities straight into the international data standard IATI. As it is connected to the IATI validator and the AIDA visualisations, organisations can be sure that they are publishing high quality data that is compliant with the IATI standard requirements.",
    data_types:
      "AIDA allows for the exploration of and publication of data published according to the International Aid Transparency Initiative (IATI) data standard. Launched in 2008, IATI is an international open data standard that aims to improve the transparency and openness of both development and humanitarian activities. IATI provides a standard promoting the regular, automated publication of open data on financial flows and also enables organisations to publish information on their project or programming activities, including information on monitoring, evaluation and results. When combined with different data, such as needs assessments and more contextual analysis, this kind of open information has the potential to drive better decision-making for the international aid sector.",
    future_proof:
      "AIDA is under constant development in order to make it as useful and intuitive as possible. Through close collaboration with the aid development community we are able to make improvements and develop new features that provide real value to users. In the future AIDA will also incorporate other data sources in order to create a comprehensive data platform. AIDA is powered by open source technologies provided by both Zimmerman and Data4Development, most notably it is powered by <a href='https://datastore.iati.cloud/' target='_blank'>IATI.cloud</a>  - the native IATI Datastore service.",
  },
  {
    id: 2,
    year: "2021",
    slug: "Openaid.fi",
    result: "200K Users",
    categories: ["Portal Design"],
    client: "Ministry for Foreign Affairs of Finland",
    preview_image: openAidMain,
    gallery: [openAidSecondary],
    techniques: ["React.js", "Nivo charts", "Node.js"],
    design: [
      "Stakeholder Workshop",
      "Personas and User Stories",
      "UI/UX Design",
      "Accessibility Testing",
    ],
    live_service: "https://openaid.fi/en/",
    link: "Openaid.fi",
    title: "Openaid.fi",
    introduction:
      "<a href='' target='_blank'>Openaid.fi</a> allows users to view all of Finland’s development corporations. This trilingual data platform presents the development spending of the Finnish Ministry of Foreign Affairs via easy to understand interactive data visualisations and a powerful search engine.",
    the_challenge:
      "Increase the transparency and accessibility of Finnish development spending data via an interactive open data portal that can be used by anyone from government officials to interested citizens.",
    process:
      "Transparency on what money is spent where, on what projects and with what results is of vital importance in the development sector. By making this information public, policy makers, donor and recipient governments are able to coordinate and plan for projects that have the maximum impact. Openly publishing development spending data also creates incentives to maximise the effectiveness of aid to ensure tax money is spent appropriately. Through this data portal the Finnish government is able to fulfil its responsibility to make data on development spending transparent and easily accessible.",
    solution:
      "The open data portal displays all current and historic development spending by the Finnish Ministry of Foreign Affairs, totalling over EUR 5 billion of disbursements. Users are able to explore the data broken down by policy priority areas, sectors, organisations, location and the UNs Sustainable Development Goals. Interactive charts allow for an intuitive way to explore the data, and the ability to drill down all the way from general aggregates to individual projects. A flexible search and filtering system allows users to easily find information related to specific queries. The portal is used by Finnish government officials to shape development policy and monitor data quality, but also provides an easy to understand overview of the Finnish development landscape for any interested person, regardless of their level of technical expertise.",
    data_types:
      "The Finnish Ministry of Foreign Affairs regularly updates and publishes data on its development spending to the IATI data standard. We make use of our own IATI Datastore and its associated API’s in order to display the most up-to-date information.",
    future_proof:
      "We will continue to work with the Finnish Ministry of Foreign Affairs to develop the platform and add new features that will increase the usability and utility of the portal.",
  },
  {
    id: 3,
    year: "2019",
    slug: "The Global Fund Data Explorer",
    result: "200K Users",
    categories: ["Portal Design"],
    client: "The Global Fund",
    preview_image: tgfMain,
    gallery: [tgfSecondary],
    techniques: ["React.js", "Nivo charts", "Node.js"],
    design: [
      "Personas and User Stories",
      "Prototyping",
      "UI/UX Design",
      "User Testing and Research",
      "Accessibility Testing",
    ],
    live_service: "https://data.theglobalfund.org/",
    link: "The-Global-Fund-Data-Explorer",
    title: "The Global Fund Data Explorer",
    introduction:
      "The Global Fund – a partnership initiative aimed at eradicating the world's major diseases like malaria, AIDS, and tuberculosis – distributes $4 billion a year to local programs in over 100 different countries. The funds raised by the Global Fund each year come in vast majority from donor governments (93%), with the remaining 7% obtained from the private sector and foundations. <p>The <a href='https://data.theglobalfund.org/' target='_blank'>Global Fund Data Explorer</a> is one of the key transparency tools of the organisation. It covers almost the entire grant lifecycle from replenishment by contributing donors every three years, to allocation to eligible countries, and down to implementation of specific projects by principal recipients.</p>",
    the_challenge:
      "Identify all steps and levels of grant lifecycle from funding decision, performance, results and audit into a granular user interface for all stakeholders to review and understand.",
    process:
      "The <a href='https://data.theglobalfund.org/' target='_blank'>Global Fund Data Explorer</a> covers every location where the Global Fund operates – which currently encompasses 67 countries, with Sub-Saharan Africa remaining by far the largest region of operation. The geographic financial overview table demonstrates distributed grant amounts per country, as well as grants awarded to multi-country programmes in different regions. <br/> The Global Fund dashboard allows users to explore the linear dimension of grant disbursement data, particularly their distribution over time, per component and per country, since the foundation of the Global Fund in 2002. For example, for the Russian Federation, HIV funding was disbursed actively between 2005 and 2009, peaking in 2008 and then eventually dipping in 2012 and going into negative value by 2018.",
    solution:
      "The Global Fund operates under the principle of performance-based funding, which makes the last tab of the dashboard perhaps the most important of them all – a snapshot evaluation of key quantitative outcomes of the programmes implemented under the Global Fund grants. The outcome indicators, based on the data available since 2017, can be filtered by component and ordered by the name of the indicator or by its quantitative measurement.",
    data_types:
      "The Global Fund produces data at regular intervals and new types of data are reviewed and integrated into the Explorer. This data is published using the internal data process within The Global Fund and are made available through the Data services provided. We make use of existing data services to connect to the Global Fund datasets and integrate the different types of data into different components on the Explorer.",
    future_proof:
      "A continuous development cycle is part of the  <a href='https://data.theglobalfund.org/' target='_blank'>Global Fund Data Explorer</a>  development, to make it more intuitive, user-friendly and tailor for data types that are expected to flow into the application. The enhancement of the map with parent-child relationships between country and region for example is also intended as the next update to make geographical exploration more logical",
  },
  {
    id: 4,
    year: "2019",
    slug: "IATI Datastore",
    result: "200K Users",
    categories: ["Data"],
    client: "Zimmerman",
    preview_image: iatiMain,
    gallery: [iatiSecondary],
    techniques: ["React.js", "Django", "Solr"],
    design: [
      "Personas and User Stories",
      "Prototyping",
      "UI/UX Design",
      "User Testing and Research",
      "Accessibility Testing",
    ],
    live_service: "https://datastore.iati.cloud/home",
    link: "IATI-Datastore",
    title: "IATI Datastore",
    introduction:
      "The <a href='https://datastore.iati.cloud/' target='_blank'>IATI Cloud Datastore</a>  provides data on development and humanitarian spending and projects that address poverty and crises across the world. It is based on an open source software developed by Zimmerman to extract and store raw IATI XML files from the IATI data Registry, and convert them into Datastore API endpoints. The IATI Datastore is based on the data service iati.cloud which is produced by Zimmerman. <p>Users can gain data on development and humanitarian resources and results through using the Datastore’s API. Users are also able to download data from the user-friendly Datastore Query Builder.</p>",
    the_challenge:
      "Synchronising static XML data from +1.500 (global) organisations operating in the humanitarian sector covering over USD140 billion in annual aid spending into a live active IATI datastore for granular access to build real time information products.",
    process:
      "The <a href='https://iatistandard.org/en/' target='_blank'>International Aid Transparency Initiative</a>  provides a standard publishing format for data on development cooperation and humanitarian aid to be shared and compared by all. Using transparent, good quality data helps efforts to achieve sustainable development. IATI sets the standard for the way information is reported so it is easier to plan, track and compare the progress and outcomes of different projects and activities. IATI data is published under an open data licence so it can be used by anyone for any purpose without prior permission to re-use.",
    solution:
      "The IATI Datastore is a fundamental data service developed by Zimmerman, as it serves as a basis for other products and services. The Devtracker front-end of FCDO Development Tracker is built on top of IATI Cloud Datastore, continuously pulling up updates via Datastore API to deliver visualised data on ODA provided by various UK Government Departments. The now discontinued service IATI Studio initially developed in cooperation with Oxfam Novib was similarly built on top of the Datastore to allow for quick and easy access to IATI data. You can still use the IATI Cloud Querybuilder to create custom queries and export results in different formats for data re-use. <p>Other organisations that make use of are UN-Migration, the Dutch Ministry of Foreign Affairs, The Overseas Development Institute, The Netherlands Enterprise agency, the AIDA Dataplatform and many more. IATI Cloud serves as the ultimate storage for IATI data across the board, where users have access to all relevant IATI data points and re-used them into data-driven information solutions. It is an end to end product for IATI data.</p>",
    technology_api:
      "The IATI Datastore has an ETL process in place that allows it to communicate with the IATI Registry, its metadata and all of the globally distributed XML files publishers by over a 1.500 organisations around the world. The technology stack is built with Python, Django and Java tools, ensuring the solution in place is optimised and spread over a set of languages rather than a single language or framework. IATI.cloud is built on top of <a href='https://solr.apache.org/' target='_blank'>Apache Solr</a>.",
    licensing:
      "Software produced by Zimmerman is openly licensed and can be found on Github for anyone to use. <a href='https://github.com/zimmerman-team/iati.cloud' target='_blank'>IATI Datastore</a>  is licensed under the AGPL Version 3 licence.",
  },
  {
    id: 5,
    year: "2019",
    slug: "Zoom",
    result: "200K Users",
    categories: ["Portal Design"],
    client: "Aidsfonds",
    preview_image: zoomMain,
    gallery: [],
    techniques: ["React.js", "Nivo charts", "Django", "GraphQL"],
    design: ["Wireframes", "Prototyping", "UI/UX Design"],
    live_service: "",
    link: "Zoom",
    title: "Zoom",
    introduction:
      "Developed in cooperation with the Aidsfonds foundation and Leiden University as one of the first data (mapping) services delivered by Zimmerman, Zoom is a unique data service that excels in offering 3 main components of data exploration: mapping, storage and visualisation. Zoom provides you ultimate control over the data sources you need to work with, as all datasets can be fed into the system and mapped to a harmonised data model. The Zoom data model is based on the <a href='https://sdmx.org/?page_id=2555#Nutshell' target='_blank'>SDMX data model</a> (Statistical Data and Metadata eXchange) and provides great flexibility and interoperability.",
    the_challenge:
      "The increased availability of data provides new opportunities to support sustainable development. In 2016, Aidsfonds started to explore how to make use of the availability of data to improve its information position in combating the Aids epidemic.",
    process:
      "This data service offers a smooth data mapping experience even for those who are not proficient IM specialists or data analysts. Once a user has uploaded a CSV formatted file, Zoom will lead that user through a vetted process of identifying column names, cleaning the dataset based on suggested errors, identifying the type and mapping column values – a process that will eventually bring you a fully mapped dataset ready for visualisation.",
    solution:
      "Zoom allows for overlay of multiple indicators to be displayed in a map view depending on the data type. It allows you to map on different administrative areas like regions, countries, counties and longitude and latitude based location points. Choropleth layers as well as proportional value bubbles are available to use. Map overlays are best used to be able to display values from two different datasets in search of possible correlation. Detailed location based maps can be produced by adding an additional layer of longitude and latitude data. Zoom also supports pie, linear and bar charts as well as data tables that you can publish in the Zoom Library in open access or available only to team collaborators. Alternatively, every created chart can be exported as webp to be shared outside the platform.",
    technology_api:
      "All mapped datasets can be assigned access permissions: public, team-based or private, thereby allowing for collaborative data exploration within a closed circle of researchers or as open data depending on data licensing in place. For organisations who collect their own data rather than work with public datasets, the possibility to restrict access rights is essential for protection of confidentiality and personal data. A separate data guideline has been produced as part of this work.",
    licensing:
      "All software produced by Zimmerman is openly licensed and can be found on Github for anyone to use. <a href='https://github.com/zimmerman-team/Zoom' target='_blank'>Zoom</a> is licensed under the AGPL Version 3 license.",
  },
  {
    id: 6,
    year: "2018",
    slug: "UN Migration IATI Data Portal",
    result: "200K Users",
    categories: ["Portal Design"],
    client: "International Organisation for Migration",
    preview_image: unMigrationMain,
    gallery: [],
    techniques: ["React.js", "Ant design charts", "Django"],
    design: ["Wireframes", "Prototyping", "UI/UX Design"],
    live_service: "",
    link: "UN-Migration-IATI-Data-Portal",
    title: "UN Migration IATI Data Portal",
    introduction:
      "Developed in cooperation with  <a href='https://www.iom.int/' target='_blank'>International Organisation for Migration</a>  (IOM) Zimmerman assisted IOM in connecting project data from their internal financial systems to become IATI compliant and provided IOM with a data portal to interface all the project data for internal use. As part of this Zimmerman created a data mapping solution that automatically transformed project data from their internal financial systems into IATI compliant data which then automatically triggered a transformation towards the IATI Registry and which then triggered a transformation for real time use on the internal data portal. This work was part of the UN Migration Agency (IOM) which joined the International Aid Transparency Initiative (IATI) back in 2017",
    the_challenge:
      "Identify all steps and levels of grant lifecycle from funding decision, performance, results and audit into a granular user interface for all stakeholders to review and understand. Key driver was the UN Migration Agency (IOM) joining the IATI initiative. Objective was to increase the transparency and accessibility of IOM spending data via an interactive open data portal.",
    process:
      "Transparency on what money is spent where, on what projects and with what results is of vital importance in the development sector. By making this information public, policy makers, donor and recipient governments are able to coordinate and plan for projects that have the maximum impact. Openly publishing development spending data also creates incentives to maximise the effectiveness of aid to ensure tax money is spent appropriately. Through this data portal IOM staff is able to fulfil its responsibility to make data on development spending transparent and easily accessible.",
    solution:
      "The IOM open data portal displays all current and historic development spending by the IOM, totalling over EUR 1 billion of aid funding. Users are able to explore the data broken down by policy priority areas, sectors, organisations, location and the UNs Sustainable Development Goals. Interactive charts allow for an intuitive way to explore the data, and the ability to drill down all the way from general aggregates to individual projects. A flexible search and filtering system allows users to easily find information related to specific queries.",
    technology_api:
      "All mapped datasets can be assigned access permissions: public, team-based or private, thereby allowing for collaborative data exploration within a closed circle of researchers or as open data depending on data licensing in place. For organisations who collect their own data rather than work with public datasets, the possibility to restrict access rights is essential for protection of confidentiality and personal data. A separate data guideline has been produced as part of this work.",
    data_types:
      "<a href='https://www.iom.int/' target='_blank'>The UN Migration Agency</a> (IOM)  regularly updates and publishes data on its development spending to the IATI data standard. It is powered by the Zimmerman IATI Datastore cloud and its associated API’s in order to display the most up-to-date information",
    licensing:
      "All software produced by Zimmerman is openly licensed and can be found on Github for anyone to use. The <a href='https://github.com/zimmerman-team/IOM-frontend' target='_blank'>IOM Front-end data portal</a> is licensed under the AGPL Version 3 license.",
  },
  {
    id: 7,
    year: "2020",
    slug: "UNESCO Transparency Portal",
    result: "200K Users",
    categories: ["Portal Design"],
    client: "International Organisation for Migration",
    preview_image: unescoMain,
    gallery: [unescoSecondary],
    techniques: ["React.js", "Django"],
    design: ["Wireframes", "Prototyping", "UI/UX Design"],
    live_service: "https://opendata.unesco.org/en/",
    link: "UNESCO-Transparency-Portal",
    title: "UNESCO Transparency Portal",
    introduction:
      "<a href='https://opendata.unesco.org/en/' target='_blank'>The UNESCO Transparency Portal</a> allows users to explore UNESCO’s projects around the world, as well as overviews of UNESCO’s donors, and where and how funds are spent.",
    the_challenge:
      "Enable public access to information about UNESCO's activities across sectors, countries, and regions, as well as details on budgetary and donor information.",
    process:
      "Public access to information is a key component of UNESCO's commitment to transparency and its accountability to stakeholders. Openness and transparency are key principles of UNESCO’s policy, as part of a commitment to Member States and their citizens, as well as the organisation’s partners. With this transparency portal, UNESCO is able to fulfil these commitments and provide users access to quality data about their activities.",
    solution:
      "The <a href='https://opendata.unesco.org/en/all-projects' target='_blank'>portal displays all of UNESCO’s activities</a>  and funding as reported to the IATI Standard. Users can explore visualisations detailing funding overviews including the financial flow, a funding breakdown by sector, data on donors, where funds are spent on a regional and national level, as well as what funds are spent on, in terms of both sectors and focus areas. Pages on individual regions, countries and projects show more detailed information, including contributions to UNESCO cross-cutting strategies and action plans as well as the UN Sustainable Development Goals. Search and filter functions allow for easy exploration of the data.",
    technology_api:
      "UNESCO publishes data on their projects and funding to the IATI data standard. We make use of our own IATI Datastore and its associated API’s in order to display the most up-to-date information.",
  },
  {
    id: 8,
    year: "2019",
    slug: "FCDO DevTracker",
    result: "200K Users",
    categories: ["Data"],
    client: "UK Foreign Commonwealth and Development Office",
    preview_image: fcdoMain,
    gallery: [fcdoSecondary],
    techniques: ["Django", "Solr"],
    design: ["Wireframes", "Prototyping", "UI/UX Design"],
    live_service: "https://devtracker.fcdo.gov.uk/",
    link: "FCDO-DevTracker",
    title: "FCDO DevTracker",
    introduction:
      "<a href='https://devtracker.fcdo.gov.uk/' target='_blank'>The FCDO Development Tracker</a> allows users to find and explore detailed information on international development and humanitarian projects funded by the UK Government. The DevTracker makes use of IATI.cloud.",
    the_challenge:
      "Providing a simple way to aggregate all IATI data published by 14 different UK government departments so that it can easily be used on the <a href='https://devtracker.fcdo.gov.uk/' target='_blank'>FCDO Development Tracker.</a> ",
    solution:
      "IATI.cloud datastore allows the Foreign, Commonwealth and Development Office to easily access IATI data published by themselves and other UK government agencies without having to build a complex back-end for the DevTracker themselves. While IATI.cloud is open-source and available for anyone to use, for this project Zimmerman set up a dedicated IATI.cloud dataspace hosted in London with added custom codes and provides specialised support as needed.",
    technology_api:
      "The IATI Datastore has an ETL process in place that allows it to communicate with the IATI Registry, its metadata and all of the globally distributed XML files publishers by over a 1.500 organisations around the world. The technology stack is built with Python, Django and Java tools, ensuring the solution in place is optimised and spread over a set of languages rather than a single language or framework.",
    licensing:
      "All software produced by Zimmerman is openly licensed and can be found on Github for anyone to use. IATI Datastore is licensed under the AGPL Version 3 license. Any work provided by FCDO is licensed under the licenses provided by FCDO.",
  },
];
