import { ProjectPalette, ProjectTypography } from "app/theme";
import { css } from "styled-components/macro";

export const appbarcss = css`
  left: 0;
  width: 100%;
  background: ${ProjectPalette.primary.dark};
`;

export const appbartoolbarcss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const appbarlinkcss = (active: boolean) => css`
  font-size: ${ProjectTypography.subtitle1?.fontSize};
  font-weight: ${ProjectTypography.subtitle1?.fontWeight};
  line-height: ${ProjectTypography.subtitle1?.lineHeight};
  text-decoration: none;
  color: ${ProjectPalette.common.white};

  > hr {
    visibility: ${active ? "visible" : "hidden"};
  }

  &:hover {
    > hr {
      visibility: visible;
    }
  }
`;

export const socialMediaCss = css`
  width: 6rem;
  height: 6rem;
  background-color: ${ProjectPalette.common.black};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 9px 46px rgba(0, 0, 0, 0.12);
  transition: all 0.5s;
  cursor: pointer;

  > svg {
    transform: scale(1.5);
  }
`;
