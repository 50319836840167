import { InsightProps } from "app/interfaces/portfolioProjects";
import OpenDataMain from "app/modules/insight-detail-module/assets/Open_data_main.webp";
import OpenDataThumbnail from "app/modules/insight-detail-module/assets/Open_data_thumbnail.webp";
import Rebrand from "app/modules/insight-detail-module/assets/Rebrand.webp";
import RebrandMain from "app/modules/insight-detail-module/assets/Rebrand_main.webp";
import RebrandThumbnail from "app/modules/insight-detail-module/assets/Rebrand_thumbnail.webp";
import IatiCloudMain from "app/modules/insight-detail-module/assets/IATI_cloud_main.webp";
import IatiCloudSecondary from "app/modules/insight-detail-module/assets/IATI_cloud_secondary.webp";
import IatiCloudThumbnail from "app/modules/insight-detail-module/assets/IATI_cloud_thumbnail.webp";
import DataPortalMain from "app/modules/insight-detail-module/assets/Data_portal_main.webp";
import DataPortalThumbnail from "app/modules/insight-detail-module/assets/Data_portal_thumbnail.webp";
import MLOpsMain from "app/modules/insight-detail-module/assets/MLOps_main.webp";
import MLOpsSecondary from "app/modules/insight-detail-module/assets/MLOps2.webp";

// Designing a data path image
import dataPathMain from "app/modules/insight-detail-module/assets/Designing a data path images/Data-path-main.webp";
import dataPathThumbnail from "app/modules/insight-detail-module/assets/Designing a data path images/Data-path-thumbnail.webp";
import dataPath1 from "app/modules/insight-detail-module/assets/Designing a data path images/pervious-approach.webp";
import dataPath2 from "app/modules/insight-detail-module/assets/Designing a data path images/Breadcrumbs-navigation-pervious.webp";
import dataPath3 from "app/modules/insight-detail-module/assets/Designing a data path images/location-based-breadcrumbs.webp";
import dataPath4 from "app/modules/insight-detail-module/assets/Designing a data path images/Attribute-based-breadcrumbs.webp";
import dataPath5 from "app/modules/insight-detail-module/assets/Designing a data path images/Path-based-breadcrumbs.webp";
import dataPath6 from "app/modules/insight-detail-module/assets/Designing a data path images/Data-viz-udget-overview-level.webp";
import dataPath7 from "app/modules/insight-detail-module/assets/Designing a data path images/Data-viz-budget-drill-down-level.webp";
import dataPath8 from "app/modules/insight-detail-module/assets/Designing a data path images/Data-viz-budget-check-data-path.webp";

// Accessibility images
import AccessibilityMain from "app/modules/insight-detail-module/assets/Accessibility/Accessibility-main.webp";
import AccessibilityThumbnail from "app/modules/insight-detail-module/assets/Accessibility/Accessibility-thumbnail.webp";
import Accessibility1 from "app/modules/insight-detail-module/assets/Accessibility/Accessibility-secondary1.webp";
import Accessibility2 from "app/modules/insight-detail-module/assets/Accessibility/Accessibility-secondary2.webp";
import Accessibility3 from "app/modules/insight-detail-module/assets/Accessibility/Accessibility-secondary3.webp";

export const insightsProjectsData: InsightProps[] = [
  {
    id: 1,
    date: "20-04-22",
    category: "Design",
    title:
      "Reflecting on a decade of building data products - Zimmerman rebranding",
    author: "Lisa Klawitter",
    timeToRead: "1min Read",
    image: RebrandMain,
    thumbnail: RebrandThumbnail,
    link: "Reflecting-on-a-decade-of-building-data-products-Zimmerman-rebranding",
    post: `
      <p>
        Since 2010 Zimmerman has specialised in providing data services , data
        dashboards, data visualisation tools and data management solutions to
        organisations in the aid and development sector.
      </p>
      <p>
        While initially not directly aimed at humanitarian organisations, what
        was then called Zimmerman & Zimmerman started as a project by two
        brothers who simply loved what they did: conducting business analysis
        and developing client-oriented solutions covering the entirety of the
        data lifecycle. Since then, Zimmerman & Zimmerman evolved into
        Zimmerman, a data services company with niche expertise in the IATI and
        development data realm.
      </p>
      <p>
        We continue to grow and evolve as a company, with big plans for the
        coming year(s). We want our new company branding to reflect these
        changes and be a better representation of our identity as a data
        services company.
      </p>
      <p>
        We are very excited to reveal our refreshed logo and branding.
        Incorporating elements of data visualisations that we use in our
        designs, the new logo alludes to the connections that can be made with
        data, and our mission to support companies and organisations to become
        more transparent and data-driven.
      </p>
      <img src=${Rebrand} alt="New brand logo" style="margin: 2rem 0;" />
      <p>
        This rebranding marks a new chapter for Zimmerman, as we look to grow
        and expand to new sectors and industries, and to help more companies to
        transform their data into meaningful information. If this sounds like
        something you would be interested in, <a href="/contact">get in touch with our team</a> to
        discuss how we could support you, or <a href="/contact#newsletter">sign up to our newsletter</a> to stay
        up to date on the latest Zimmerman news.
      </p>`,
  },
  {
    id: 2,
    date: "20-04-22",
    category: "Data",
    title: "Five reasons your organisation needs an open data portal",
    author: "Lisa Klawitter",
    timeToRead: "3min Read",
    image: DataPortalMain,
    thumbnail: DataPortalThumbnail,
    link: "Five-reasons-your-organisation-needs-an-open-data-portal",
    post: `
      <h5 className="h5Css">What is a data portal?</h5>
        <p>In simplest terms, a data portal is an online platform which allows users to access a collection of data. These can be internal portals to share data within an organisation, or open data portals to share data publicly. Portals serve as a single source of truth for data on an organisation or a particular topic.</p>
        <p>Within the data portal interactive visualisations can make it easier to explore and understand complex data, and flexible search and filter mechanisms allow users to quickly find answers to specific queries. Open data portals can be used by governments such as the <a target="_blank" href="https://openaid.fi/">Finnish Ministry of Foreign Affairs</a>, or INGOs like <a target="_blank" href="https://data.theglobalfund.org/">The Global Fund</a>, to communicate how and where funds are spent, as well as the impact of their projects</p>
      
      <ol>
        <li>
          Transparency <br />
          Transparency on what resources are being spent where and with what results is vital in the public sector. Organisations need to be accountable to their donors and stakeholders, and transparent data sharing enables better planning and coordination across the sector. Open data portals are a key to fulfilling your transparency obligations, enabling anyone to easily access the published data.
        </li>
        <li>
          Data accessibility <br />
          Data portals allow you to consolidate all your data in one place, making it more accessible to both external and internal parties. Rather than scrambling to find the relevant spreadsheet or datapoint in an internal database, if you get an inquiry from an external party about your data you can simply point them to your data portal. Additionally, it becomes easier to share data across your organisation, reducing data becoming siloed within separate departments.
        </li>
        <li>
          Communicate your impact<br />
          Your organisation spends a lot of time and resources trying to solve a particular problem or challenge, but how do you effectively communicate the impact of your work to outsiders? Open data portals can support your communications efforts with real results data, allowing you to create more effective campaigns to increase funding and awareness. 
        </li>
        <li>
          Improve the quality of your data<br />
          By aggregating your data in visualisations, it becomes easier to spot errors or gaps in your data, without having to comb through datasets individually. Because the data is publicly available there is also more incentive to keep data accurate and up-to-date. This will benefit the organisation in the long-term as high quality data is vital for improved decision making and resource allocation.
        </li>
        <li>
          Encourage data-driven decision making<br />
          By making your data more accessible, it becomes easier to use the data to gain actionable insights. This is true within organisations, as staff will have access to cross-departmental data, but also across organisations. By making data available publicly, it is possible to get better insight into what is happening across the entire sector, allowing for better coordination in order to maximise impact.
        </li>
      </ol>

      <h5 className="h5Css">How can you build your own portal?</h5>
        <p>The first step to creating your own data portal is a coherent data architecture. If you are an organisation within the development sector and you are already publishing to the International Aid Transparency Initiative (IATI) data standard, you can make use of our <a target="_blank" href="https://datastore.iati.cloud/">IATI.cloud</a> API endpoints to build your own portal or you could make use of <a target="_blank" href="https://aida.tools/">AIDA.tools</a>, a one-stop SaaS (Software as a Service) open data platform we co-authored with Data 4 Development.</p>
        <p>If you need more support to create your data portal, we’re here to help. At Zimmerman we have <a href="/portfolio">over a decade of experience</a>  building data services and data portals for international organisations and governments. We create the perfect portal according to your organisation’s needs, including custom visualisations and complex search and filtering mechanisms. If you’d like to find out more, <a href="/contact">get in touch with our team</a>  to discuss how you can benefit from our services.</p>
    `,
  },
  {
    id: 3,
    date: "20-04-22",
    category: "Data",
    title: "Major Benefits of Open Data",
    author: "Lisa Klawitter",
    timeToRead: "2min Read",
    image: OpenDataMain,
    thumbnail: OpenDataThumbnail,
    link: "Major-Benefits-of-Open-Data",
    post: `
      <h5 className="h5Css">What is open data?</h5>
        <p>We’ve all heard it before - “data is the new oil”. Data has quickly become one of our most important assets, in both the public and private sectors. It can help us make better decisions, solve problems and grow and improve businesses. But data can also be costly and time-consuming to come by, which is why open data is so important.</p>
        <p>The <a target="_blank" href="https://opendatahandbook.org/">Open Data Handbook</a> defines open data as follows:</p>
        <cite className="insightQuoteCss" >“Open data is data that can be freely used, re-used and redistributed by anyone - subject only, at most, to the requirement to attribute and sharealike.”</cite>
        <p>This means that data needs to be available and accessible to anyone, in a convenient and modifiable form, without any restrictions on its reuse and re-distribution.</p>
        <h5 className="h5Css">But why should you care? Why is open data important?</h5>
      <ol>
        <li>Open data encourages innovation <br />
          <p>Open data allows businesses to solve problems based entirely on publicly available data, which is particularly important for startups and smaller companies, who might not otherwise be able to invest in procuring or collecting data.</p>
          <p>One example of this is Citymapper, the public transit and mapping app which was originally built on data made public by TfL, and was able to expand to many of the major cities across the world thanks to the increasing availability of open data.</p>
        </li>
        <li>Open data promotes transparency<br />
          Transparency is the cornerstone of a well-functioning democracy. Particularly when it comes to the flow of (public) funds, citizens and stakeholders have a right to know how, where and when money is being spent. This is to promote accountability and reduce the opportunity for corruption. It also makes it easier to find areas of improvements, and encourage more efficient projects and policies.
        </li>
        <li>Open data can inspire participation and engagement<br />
          <p>Most of the time citizens are only able to truly engage with their government sporadically, maybe at an election every 5 years or so. By making data publicly available, it becomes much easier to understand how public funds are being spent, and what shapes policy decisions. This means people are able to actively engage in these processes and launch into activism armed with the right data.</p>
          <p>Are you interested in how your organisation can make use of open data, or even how you can make your own datasets available publicly? Then <a href="/contact">get in touch with our team</a>  to discuss how we can help.</p>
        </li>
      </ol>
    `,
  },
  {
    id: 4,
    date: "20-04-22",
    category: "Data",
    title:
      "Rebuilding IATI.cloud for speed, data consistency, maintainability and usability",
    author: "Sylvan Ridderinkhof",
    timeToRead: "4min Read",
    image: IatiCloudMain,
    thumbnail: IatiCloudThumbnail,
    link: "Rebuilding-IATI.cloud-for-speed-data-consistency-maintainability-and-usability",
    post: `
        <h5 className="h5Css">IATI.cloud</h5>
        <p>The IATI.cloud is a long-running project by the Zimmerman team, starting back in 2013. Along the way, many positive changes have been made and many adventures have been completed. The IATI.cloud contributed to the improvement of the usage of IATI data, and has supported Zimmerman to build data platforms such as <a href="https://aida.tools/">AIDA</a> and an <a href="https://openaid.fi/">Openaid</a> data portal for the Finnish government. Zimmerman introduced a second data endpoint by using Apache Solr to provide faster access to the IATI data.</p>
        <h5 className="h5Css">Current process</h5>
        <p>Currently, IATI.cloud processes all of the IATI compliant data from the <a href="https://iatistandard.org/en/iati-tools-and-resources/iati-registry/">IATI Registry</a>, an open Registry for anyone that would like to open up their data in the aid development world, by automatically downloading all of them separately, then parsing all of them using complicated parsing business rules, and then lastly once an activity has been parsed, this activity is used to create a document which is sent over to Solr to be indexed. For a “complete run”, historically we have seen this take anywhere from 10 to 20 days, depending on the performance of the machines that are running the process. This process started out as just parsing the datasets into Django objects and storing them in a Postgres database. Alongside this, we store information about publishers, codelists, custom codelists and currencies to have a very complete dataset available for our users.  The integration with Apache Solr was added later, as an additional feature. All these changes brought with them overhead, undocumented code and hard (nigh impossible) to keep alive.</p>
        <h5 className="h5Css">Goal</h5>
        <p>For IATI.cloud to move forward, we wanted a way to quickly index all the available datasets. A decision had been made previously to exclusively start using Apache Solr. We know that Solr should be able to index datasets relatively quickly. Therefore we set the following goal: <br /> <strong>We want to directly index datasets to Solr, without having to use the Django object integration, to reduce IATI.cloud processing time.</strong> </p>
        <h5 className="h5Css">Results</h5>
        <img src=${IatiCloudSecondary} alt="IATI.cloud Result image" style="box-shadow: 0px 3.35117px 17.1282px rgba(0, 0, 0, 0.12);" />
        <p>The picture above shows a high level overview of the resulting process. The result of this process is that we now index the publisher metadata, dataset metadata, IATI organisation XML files and IATI activity XML files. Along with these activities we provide extracted transactions, budgets and results. We also provide some additional functionality such as extracted activity dates, codelist “pretty names”, currency conversion and precalculated aggregations for individual transaction types. And the best thing: we do it all in around <strong>five hours!</strong> We are still looking for more opportunities for optimization, but this is a great leap forward. This means your data is updated every single day.</p>
        <p>While rebuilding this IATI.cloud process, we also determined that the field names that were previously used were incoherent and not very human-readable. We have attempted to change this by sticking to the IATI Standard’s way of representing fields, where names are concatenated by dashes, and deeper level fields are concatenated by periods. This gives the user a clear idea where the specific value has come from.</p>
        <p>Of course, this raises the questions “what will happen to the old Django API?” and “I won’t be able to access the old field names!?”. The latter has an elegant solution in place, namely we provide access to these old field names by using Solr’s “Copyfields”, these will be available for some time, and a document will be provided to help users transition to the new field names. The former is something that Zimmerman has been working on for a while, the “Api v2” was released more than a year ago, and has been the main entrypoint for both the formal IATI Datastore and any publications from Zimmerman’s side. The Django API will remain available for some time, but will be shut down at some point in the future. A positive note, the Django API will be up to date more rapidly, due to decoupling the Solr API from the Django data processing pipeline.</p>
        <p>This new process has been developed separately from the old codebase, and we have taken many steps to ensure there is no technical debt, there are no bugs or code smells and no security risks. The code is well documented and therefore much more easily maintainable for future development. Additional custom features are also easy to add and the process of making these changes will be much smoother. Where a change to the feature pool would previously cost us two weeks to publish and provide access to, will now only take a few hours</p>
        <p>Are you interested in how your organisation can make use of the IATI.cloud, or how you should move forward with these changes to the IATI.cloud? Then <a href="/contact">get in touch with our team</a> to discuss how we can help.</p>
    `,
  },
  {
    id: 5,
    date: "25-05-22",
    category: "Data",
    title: "Zimmerman’s efforts with MLOps",
    author: "Francesco Stablum",
    timeToRead: "7min Read",
    image: MLOpsMain,
    thumbnail: MLOpsMain,
    link: "Zimmerman’s-efforts-with-MLOps",
    post: `
        <h5 className="h5Css">Introduction</h5>
          This post will mostly focus on specific engineering and MLOps challenges that have been encountered. Future posts may, conversely, put more attention on the theoretical details of the models employed as well as a report of our results and findings.
          We are working on Machine Learning technologies that will allow us to gather automatic insights on  <a href="https://iatistandard.org/">IATI Data</a>. Such insights may be, for example, a measure of “semantic distance” of a IATI datapoint with any other datapoint. This may result in being helpful in automatically obtaining a similarity metric between IATI activities, and to be able to effectively group them (a method known as “clustering”). Also, such models may help us with data imputation, which is automatically filling missing fields with estimated values.</p>
        
        <h5 className="h5Css">The challenge</h5>
          <p>A IATI datapoint is very complex, containing an arbitrary amount of information. This aspect is conflicting with conventional ML architectures that only accept fixed-length data points as their input. Specifically, there are relational fields in an activity datapoint that contain sets of items. The main challenge was then to be able to “squash” this arbitrarily-long amount of information into a fixed-length vector and then replace the field with such a vector. Resulting in fixed-length activity datapoints. Subsequently, it’s possible to project such vectors themselves into a low-dimensional space by employing an additional autoencoder.</p>
        
        <h5 className="h5Css">Our attempt to a solution for learning sets of items</h5>
          <p>What we found is that “Deep Sets” models tackle exactly this problem. Specifically <em>Deep Sets Prediction Networks</em> ( <a href="https://arxiv.org/abs/1906.06565">IATI Data</a> ). The use of these models was not straightforward as they aren’t coming as ready-to-use python packages, and their in-code documentation is lacking, so a lot of adjustments were necessary to make them usable for our purposes.</p>
        
        <h5 className="h5Css">Software engineering practices for data preprocessing</h5>
          <p>A number of software engineering practices have been employed to ensure readability and reusability of the code. For example, the logics pertaining to the extraction of data from fields and its conversion to a ML-friendly format have been organised in an object-oriented system. A number of field type-specific classes have been created. These classes share the same methods signature of a generic Field parent class they are inheriting from. Such methods are, for example, providing the dimensionality (number of features) of the vector-chunk they refer to, encoding logic of the field data into the vector representation, which loss function they need and a decision on when two real-valued field representations shall be considered equal; this latter aspect is useful within autoencoded reconstructions to evaluate if the model is actually functioning as expected or not.</p>
       
       <h5 className="h5Css">Organised management of preprocessing tasks</h5>
          <p>The challenges of employing machine learning methods go way beyond merely preparing a prototype. A production-ready loop of data collection, preprocessing, model training, model storage and model deployment needs to be implemented.
             Luckily there are multiple enterprise-grade tools that solve various aspects of this loop. Data collection and data preprocessing can be split in pseudo-atomic chunks of work. Also the need for scheduling such tasks led to the employment of what seems to be the de facto standard for such issues, which is Apache Airflow.
             Airflow surely had a number of challenges. One is a very slow interface once the dependency graph of the tasks becomes very large. For this reason we developed a text-based task exploration tool which runs from a terminal, with an ncurses wizard-menus interface, and directly accesses Airflow’s PostgreSQL database to gather information about the status of the tasks.</p>
       
       <h5 className="h5Css">Storage of intermediate preprocessing yields</h5>
          <p>The choice to store intermediate stages of the preprocessing of IATI activity data has been MongoDB. It allowed a straightforward storage of documents with arbitrary and hierarchical fields without the need of preliminarily defining tables with a fixed structure. Moreover MongoDB has been subject to marked improvements over the years that made it competitive in terms of feature set, to most advanced SQL-based systems, for example, by the introduction of JOIN operations. <br/>
              We did find that MongoDB does have some drawbacks: the amount of data it can store as a BSON (Binary Javascript Object Notation) object has an upper-bound that cannot be changed. This is a problem, since we need to store the numpy array as a serialised binary object. Luckily, we found and employed an abstraction framework, GridFS, that is built on top of MongoDB that allows us to store arbitrary-long objects by - under the hood - splitting them into chunks. </p>

       <h5 className="h5Css">Monitoring of model training</h5>
          <p>Two systems have been employed in order to monitor the training evolution of the models: MLFlow and grafana. The former stores the various model training data and allows for quick comparisons of the various model performances visualised in tables. Also, every model training run has its own plots in which the evolution of metrics is displayed against the number of epochs. The latter, grafana, offers a completely different overview on model training as the plots are organised in wall-clock-time-based dashboards. This helps in understanding what is happening right now in the training machine, especially when it comes to training times - it’s easy to see which models are training faster and which slower. Moreover using grafana allows to display all the model training at once and provides graphically the history of model training sessions, thus enabling intuitions at a glance on how the project is going and which models perform better. <br/>
              In order to deliver the metrics to grafana, we had to put in place the prometheus time-series-based monitoring system and we had to develop MLFlow exporter, which is an ad-hoc metric collection and exposition tool that scans MLFlow’s yields to gather the last metric observation for each model training run to be provided to prometheus.</p>
       
       <h5 className="h5Css">Trained model persistence system</h5>
          <p>Currently, trained models are being saved as regular files. A versioning system, encoded in the filename allows for switching between models that have been trained with different parameters. When looking for a model, the last version is automatically going to be selected. Pytorch lightning conveniently provides callback model persistence functions whose purpose it’s to store “checkpoint” models once every number of epochs. <br/>
              In the future, alternative systems such as databases with BLOB fields may be employed, or maybe we could even store them into MongoDB with GridFS, since we already successfully tested this solution with dataset persistence.<p/>
       
       <h5 className="h5Css">Deployment</h5>
          <p>Particular care has been put on making sure that the entire system is easily deployable on any machine. It’s particularly important to tackle this aspect in a systematic and organised way as the services being employed are many. The following chart shows the interaction/dependencies among them: <br/></p>
          <img src=${MLOpsSecondary} alt="MLOps image" style="box-shadow: 0px 3.35117px 17.1282px rgba(0, 0, 0, 0.12);" />
          <p>Reproducibility of the installation steps is key here, as well as simplification of data migration processes. The natural choice to solve this aspect has been Docker. <br/>  Docker, aided by the framework of docker-compose, allows to deploy each individual service in a separated isolated environment that resembles a very lightweight virtual machine. All installation steps for a service are precisely encoded and those virtual filesystem changes are individually cached as well. All these so-called docker containers lie in a dedicated virtual network and the access from outside can be granularly determined.</p>
       
       <h5 className="h5Css">Open source software</h5>
          <p>One of the reasons that open source software is employed is the reduction of costs, with no expensive licences required. Moreover, most high-quality solutions to specific challenges seem to be open source and the support communities around them are vibrant. <br/> Having access to all source code has been crucial in a few occasions in order to get an understanding on why a certain component was malfunctioning.</p>
       
       <h5 className="h5Css">Challenges and final remarks</h5>
          <p>The creation of a data-to-model pipeline is not an easy task. Various technologies, even if they are extremely powerful and offering targeted solutions, are still undergoing bug fixing, refinements, backward-incompatible changes, lack of documentation and cryptic error messages. One could say that this is a very normal situation in software engineering. Anyways the employment of a large number of technologies, each dedicated to a specific issue just multiplies the number of the aforementioned problems - but this was quite unavoidable in order to prevent “reinventing the wheel”. I’m certain that further developments of all these technologies, combined with our expanding expertise, will lead to quicker identifications of solutions and overall more oiled development processes.</p>
`,
  },
  {
    id: 6,
    date: "20-04-22",
    category: "Design",
    title: "Designing a data path",
    author: "Dafei Lei",
    timeToRead: "5min Read",
    image: dataPathMain,
    thumbnail: dataPathThumbnail,
    link: "Designing-a-data-path",
    post: `
        <h5 className="h5Css">A navigation UX improvement for The Global Fund Data Explorer.</h5>

        <h5 className="h5Css">Introduction</h5>
        <p><a href="https://data.theglobalfund.org/" target="_blank">The Global Fund Data Explorer</a> Data Explorer is a key transparency tool for the organisation. It allows users to look at data from different perspectives and also to freely interact with the data. However, with the freedom that the users have and the structure of the data, it also makes it difficult to navigate the data exploring journey. So how could we improve data navigation experience？
        <h5 className="h5Css">Previous approach</h5>
        <img src=${dataPath1} alt="Previous Approach" />
        <p>This screenshot shows the previous data explorer interface design, where every time users drill into the next level in a dataset, they will have a panel pop up. The panel allows the users to see the connection between two levels. Meanwhile, there is also a breadcrumb above to show the user’s drill down path. The  goal of the design is to let the users clearly know where they are in the data exploring journey. However with the user test we got the following feedback：
        
        <ol>
          <li>The panel could not provide much value when the users keep drilling down into deeper levels. The panel approach works well when there are only 2 levels, but when it comes to 3 levels the users start to become confused.</li>
          <li>With the panel, there is a limitation of some functions in the control panel when users start their drill down process. As we show two levels at the same time, users would feel confused about which level the control panel is controlling</li>
        </ol>
        
        <p>By looking at the feedback,  we decided to remove the panel and only use breadcrumbs to communicate the relation of drill down steps in order to make the interface clean and flexible.</p>
        <img src=${dataPath2} alt="Breadcrumbs Approach" />
        <p>If we only use breadcrumbs to communicate where the user’s current location is, then the breadcrumbs should be very clear and easy to read. But because of the data structure and the length of the terms, the breadcrumbs could be super long when the user drills into deeper levels. And they could only see part of the long names. </p>
        <p> <strong>So what kind of breadcrumbs navigation could help users to navigate the complicated data exploration journey?</strong> </p>
        
        <h5 className="h5Css">About Breadcrumb Types</h5>
        <p>In order to know how many kinds of breadcrumb navigations there are, I did some desk research. Breadcrumbs are a set of contextual links that function as a navigation aid for websites. Basically, it’s a secondary navigation scheme that reveals the user’s location in a website. The term comes from the Hansel and Gretel fairy tale in which the two children drop breadcrumbs to form a trail back to their home.
        
        <p>From the research, in general there are three different types of breadcrumbs represented in websites — <strong>location, attribute, and path.</strong> </p>
        
        <ol>
          <li> <strong>Location based</strong> </li>
          <p>Location breadcrumbs are static and show where the page is located in the website hierarchy. They help visitors understand and navigate a website's hierarchy, which has multiple levels (often distinct levels).</p>
          <img src=${dataPath3} alt="Location Based Image" />
          
          <li> <strong>Attribute - based</strong> </li>
          <p>Attribute breadcrumbs give information that categorises the current page. The most common examples are ecommerce sites that use breadcrumbs as a type of extended keyword to convey product meta-information, such as subject, price, category, style, and brand.</p>
          <img src=${dataPath4} alt="Attribute Based Image" />
          
          <li> <strong>Path-based</strong> </li>
          <p>Path breadcrumbs show users the steps they took to end up on the current page.This type of breadcrumb link is dynamically generated. Sometimes path-based breadcrumbs can be helpful, but this time they’re not very useful— visitors tend to jump from one page to the other and the trail they create can be chaotic. A “Back” button available in the browser is more helpful. Finally, a history trail is useless for visitors who arrive directly at a page deep within the site.</p>
          <img src=${dataPath5} alt="Path based Based Image" />
          
          <p>Meanwhile, From the UX research of Nielsen Norman Group, breadcrumbs are used to show the site hierarchy, not the user’s history. So normally we use location-based and attribute-based breadcrumbs. Not path-based ones </p>
          <em>“Breadcrumbs should show the site hierarchy, not the user's history. (I.e., use location-based breadcrumbs, not path-based ones.)”</em>
          
          <p style="text-align: right; position: relative; right: 0">- Jakob Nielsen, Nielsen Norman Group</p>
          <p>By looking at examples of location-based and attribute-based breadcrumbs, it shows that they are concise, and if there are long section names, it will avoid long and redundant breadcrumbs by using shorter alternatives for section names. However, for a dataset, the terms could be very long and not easy to recognize if we only use short alternatives.</p>
          <p> <strong>So How could I create more space for showing the drill down path with longer names in the interface design?</strong> </p>
        </ol>

        <h5 className="h5Css">Solution</h5>
        <p>With the question in my mind, I started making experiments. If the horizontal space is limited, can we have a vertical panel/space and have the data path navigation displayed in a vertical way?  In this way, even if the user drills into deeper levels, we will still be able to have enough space to show the drill down steps (with the scroll function). And also it’s easier for users to read the long name in each drill down level.</p>
          
          <img src=${dataPath6} alt="Path based Based Image" title="Data viz(budget) overview level"  description="Data viz(budget) overview level" caption="Data viz(budget) overview level" />
          
          <img src=${dataPath7} alt="Path based Based Image" />
          <img src=${dataPath8} alt="Path based Based Image" />

        <p>We did a small group testing in the team, which already got some good feedback. But In order to have a good understanding about how much improvements have been made, next round big scale user testing will be taken.</p>

  `,
  },
  {
    id: 7,
    date: "20-04-22",
    category: "Design",
    title: "How we’re trying to make our data services more accessible",
    author: "Lisa Klawitter",
    timeToRead: "4min Read",
    image: AccessibilityMain,
    thumbnail: AccessibilityThumbnail,
    link: "How-we’re-trying-to-make-our-data-services-more-accessible",
    post: `
        <p>Accessibility is at the heart of what we do at Zimmerman - we create dashboards and portals that distil large and complex datasets into visualisations that are easy to understand, navigate and use. However, while we’ve always tried to make our products and services accessible to people with various levels of technical expertise and domain knowledge, they haven’t always been inclusive of people with disabilities. Over the past year we have made a concerted effort to get our products to meet accessibility guidelines, so that our products can truly be used by anyone.</p>
        <h5 className="h5Css">Accessibility guidelines and checklists</h5>
        <p>We started by comparing our products to the <a href="https://www.w3.org/TR/WCAG21/" target="_blank">WCAG standard</a>, with the goal to meet all AA level requirements. Somewhat ironically, we found that the guidelines can be quite intimidating and confusing to understand when we were first getting started. Luckily, there are plenty of resources available online that do a great job of explaining the requirements and how to meet them. We found <a href="https://www.wuhcag.com/wcag-checklist/" target="_blank">wuhcag.com</a>  particularly useful in how they outlined the requirements, alongside tips on how to meet them.</p>
        <p>Working with (interactive) data visualisations added another layer of complexity to our testing process. <a href="https://chartability.fizz.studio/" target="_blank">Chartability</a> was a useful guide in this; with a set of heuristics focused on creating an inclusive data experience for people with disabilities. The <a href="https://chartability.fizz.studio/" target="_blank">workbook</a> provided an easy way to audit our work and catch accessibility failures in our designs.</p>
        <p>Below are four of the requirements that are critical to accessibility, how we tested them and any adjustments we made based on the tests.</p>
        
        <h5 className="h5Css">Colour</h5>
        <p>In order to be mindful of those with colour blindness or visual impairments, we use a <a href="https://www.figma.com/community/plugin/733343906244951586/Color-Blind" target="_blank"> colour blind plugin </a> on figma to test colour palettes and designs. This allows us to understand how users with different colour vision deficiencies see our designs, and whether they would be able to use it properly.</p>
        <p>Another important aspect to consider is that colour should not be the only means of conveying information. This can be tricky in data visualisations, where colour is often used to differentiate between components on charts. One way we’ve tried to overcome this is by adding different outlines to elements, like in the legend below, where eligibility is indicated by both colour and the line around the circle.</p>
        <img src=${Accessibility1} alt="Colors"/>
        
        <h5 className="h5Css">Contrast</h5>
        <p>Sufficient contrast is vital to be able to accurately differentiate between different elements on the page. Luckily, there are many plugins on figma that make this easy to test. <a href="https://www.figma.com/community/plugin/872423614371258087/Color-Luminance" target="_blank"> Color Luminance </a> can automatically calculate the contrast ratio between different colours, and <a href="https://www.figma.com/community/plugin/733159460536249875/A11y---Color-Contrast-Checker" target="_blank"> A11y - Color Contrast Checker  </a>allows you to see whether there is enough contrast between text and its background for an entire screen.</p>
        <p>Below you can see a screenshot of an initial design we created, which clearly did not have enough contrast between different visual elements. Changing the colour palette and adding thin outlines makes it a lot easier to look at.</p>
        <img src=${Accessibility2} alt="contrast"/>
        <img src=${Accessibility3} alt="contrast"/>

        <h5 className="h5Css">Keyboard accessibility</h5>
        <p>Users should be able to access and navigate through a site using their keyboard only. This can easily be tested by tabbing through the site yourself, and you’ll be able to spot any elements that you cannot access with your keyboard alone, areas where a keyboard user might get trapped and whether keyboard focus is visible.</p>
        
        <h5 className="h5Css"> Screen reader accessibility</h5>
        <p> Users with visual impairments that make use of screen readers need to be able to navigate the site. To test this I use VoiceOver, the built-in screen reader on Mac, and then see whether I can fully navigate the site without relying on visuals. This is useful to highlight whether items are accurately labelled e.g. a button whose label is just “button” isn’t particularly useful if a user with a screen reader can’t tell what its function is. </p>
        
        <h5 className="h5Css"> It’s a team effort </h5>
        <p> Accessibility is not something that can be worked on in isolation. Designers should be aware of accessibility guidelines like contrast, colour, and font sizes, and developers need to consider keyboard accessibility, correct ARIA labelling and semantic HTML. If everyone in the team is mindful of accessibility then this can save time - sites are designed and implemented to be accessible from the get-go, rather than having to go back and make corrections after the fact. </p>
        
        <h5 className="h5Css"> It’s a work in progress</h5>
        <p> We are by no means experts in the field of web accessibility, nor do all our sites perfectly meet all the requirements now. But we are continuing to learn and increase awareness of the importance of accessibility within the team so that we can make our tools and products inclusive and accessible to everyone. </p>
    `,
  },
];

//  <a href="" target="_blank"> </a>
//         <h5 className="h5Css">  </h5>
//      <p>  </p>
