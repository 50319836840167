import { createStore, reducer } from "easy-peasy";
import { StoreModel } from "app/state/model/portfolioProjectsModel";
import { portfolioProjectsData } from "app/state/data/portfolioProjects";
import { insightsProjectsData } from "app/state/data/insightsProjectsData";
import { serviceDetailData } from "app/state/data/serviceDetailData";

const store = createStore<StoreModel>({
  portfolioProjects: reducer((state = portfolioProjectsData, action) => {
    switch (action.type) {
      case "FILTER_PORTFOLIO_PROJECTS":
        switch (action.payload) {
          case "All":
            return portfolioProjectsData;
          default:
            return portfolioProjectsData.filter(
              (p) => p.categories.indexOf(action.payload) > -1
            );
        }
      default:
        return state;
    }
  }),
  insights: reducer((state = insightsProjectsData, action) => {
    switch (action.type) {
      case "FILTER_INSIGHTS_PROJECTS":
        switch (action.payload) {
          case "All":
            return insightsProjectsData;
          default:
            return insightsProjectsData.filter(
              (insight) => insight.category === action.payload
            );
        }
      default:
        return state;
    }
  }),
  services: reducer((state = serviceDetailData, action) => {
    switch (action.type) {
      case "FILTER_SERVICES":
        switch (action.payload) {
          case "All":
            return serviceDetailData;
          default:
            return serviceDetailData.filter(
              (service) => service.text === action.payload
            );
        }
      default:
        return state;
    }
  }),
});

export default store;
