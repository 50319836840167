export interface AppBarPageModel {
  title: string;
  path: string;
}

export const pages: AppBarPageModel[] = [
  {
    title: "Portfolio",
    path: "/portfolio",
  },
  {
    title: "Services",
    path: "/services",
  },
  {
    title: "Insights",
    path: "/insights",
  },
  {
    title: "Team",
    path: "/team",
  },
  {
    title: "Mission",
    path: "/mission",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
