import RequirementGathering from "app/assets/images/cards/service-detail/RequirementGathering.svg";
import ServiceDelivery from "app/assets/images/cards/service-detail/ServiceDelivery.svg";
import DataDashboards from "app/assets/images/cards/service-detail/DataDashboards.svg";
import CloudServices from "app/assets/images/cards/service-detail/CloudServices.svg";
import FunctionalDesign from "app/assets/images/cards/service-detail/FunctionalDesign.svg";
import ManagingDataSources from "app/assets/images/cards/service-detail/ManagingDataSources.svg";
import DataViz from "app/assets/images/cards/service-detail/DataViz.svg";
import UserExp from "app/assets/images/cards/service-detail/UserExp.svg";

export const serviceDetailData = [
  {
    id: 1,
    serviceURI: "requirement-gathering-and-analysis",
    text: "Service",
    serviceName: "Requirement gathering and analysis",
    link: "Requirement-gathering-and-analysis",
    serviceItems: [
      "Analysing exactly what the project or service needs",
      "Personas, user stories",
    ],
    serviceDescription:
      "In order to ensure that the product we create is actually beneficial to the user, we undertake extensive research into the requirements of a project. As part of this we create personas, which are fictional characters created to represent a user type. Based on these personas we then write up user stories, which outlines the features a user might want and the benefits it would bring them. Personas and user stories are created in collaboration with the client, and serve as a reference tool throughout the development process to ensure all features remain aligned with requirements.",
    serviceImg: RequirementGathering,
  },
  {
    id: 2,
    serviceURI: "user-experience-and-interface-design",
    text: "Service",
    serviceName: "User experience and interface design",
    link: "User-experience-and-interface-design",
    serviceItems: [
      "Wireframes",
      "Responsive/mobile design",
      "WCAG requirements for accessibility",
      "User testing",
    ],
    serviceDescription:
      "We aim to create beautiful user interfaces with an intuitive user experience. In order to achieve this, we initially create a sitemap and interfaces, which are then built up into a complete design. We will create an interface with a unique look and feel appropriate for the specific project, taking into consideration the usability and interactive elements. At all stages of the design and implementation of the product, we take care to ensure the site meets WCAG accessibility requirements, so that is accessible and usable by everyone. Where applicable, we will create a responsibe and/or user dedicated mobile design to ensure the product is usable in different services. Throughout the design process, we also offer the possibility to carry out user testing to discover how real users interact with the product, and identify areas for improvement.",
    serviceImg: UserExp,
  },
  {
    id: 3,
    serviceURI: "functional-and-technical-design",
    text: "Service",
    serviceName: "Functional and technical design",
    link: "Functional-and-technical-design",
    serviceItems: [
      "Functional and non-functional requirements",
      "Technical infrastructure necessary for the project",
      "Analysis of current data architecture",
    ],
    serviceDescription:
      "Prior to building a new data product or services we will analyze the functional and non-functional requirements: these describe what the system should do and how it will work. We then examine the technical infrastructure necessary for the project, as well as the current data architecture and any data constraints.",
    serviceImg: FunctionalDesign,
  },
  {
    id: 4,
    serviceURI: "service-delivery-and-integration",
    text: "Service",
    serviceName: "Service delivery and integration",
    link: "Service-delivery-and-integration",
    serviceItems: [
      "Front end implementation",
      "Data connection",
      "Open source license",
      "Aftercare and servicing",
    ],
    serviceDescription:
      "As part of data service delivery and integration, the visual design is implemented according to the technical requirements. Following the design implementation, the data is connected to the relevant interface elements. If required, we can also connect the interface to a Content Management System (CMS) to allow the client to easily change and update static non-data content and enable multi-language functionality. All data services and software applications developed by Zimmerman are licensed under the GNU AGPL V3 which is an open-source license. Regular maintenance, servicing and support is available following the launch of a product.",
    serviceImg: ServiceDelivery,
  },
  {
    id: 5,
    serviceURI: "managing-data-sources",
    text: "Service",
    serviceName: "Managing data sources",
    link: "Managing-data-sources",
    serviceItems: [
      "Interfaces to easily publish data",
      "Data storage, validation and processing",
      "Transforming raw data files into API endpoints",
    ],
    serviceDescription:
      "We can support companies to manage complex and rich data sets. We can create intuitive interfaces that make publishing and uploading data easier and more efficient, either via form-filling or bulk upload. We also offer data storage, validation and processing: for example, we can transform raw files into API endpoints, which can be used as a starting point for other data services such as dashboards and visualisations. Additionnaly, we use Apache Solr as search engine on top of our data services to index, query, map and rank data.",
    serviceImg: ManagingDataSources,
  },
  {
    id: 6,
    serviceURI: "building-data-dashboards",
    text: "Service",
    serviceName: "Buiding data dashboards",
    link: "Buiding-data-dashboards",
    serviceItems: [
      "Interactive",
      "Aggregate data",
      "Filter and search",
      "Drilldown into data",
    ],
    serviceDescription:
      "We create custom-built data dashboards and portals that allow organisations to display data in an open and transparent manner, as well as providing a user friendly way to explore, compare and analyse data. We can create hierarchical aggregations of the data which mean users are able to drill down into the data, from high-level overviews to individual data points. Additionally, via flexible search and filtering systsems, users are able to find answers to specific queries. Our data dashboards and portals allow users to explore complex data in a way that is accessible and intuitive.",
    serviceImg: DataDashboards,
  },
  {
    id: 7,
    serviceURI: "data-visualisations",
    text: "Service",
    serviceName: "Data visualisations",
    link: "Data-visualisations",
    serviceItems: [
      "Custom made to display data into in easy to understand way",
      "Interactive",
    ],
    serviceDescription:
      "We create beautiful data visualisations that communicate complex information in a simple way. Our visualisation techniques create meaningful visual stories and actionable insights. Each data set is unique, and so are our visualisations, with each one carefully considered in order to present information to the most valuable manner. For highly complex data sets, we are able to create innovative solutions to make your data stand out.",
    serviceImg: DataViz,
  },
  {
    id: 8,
    serviceURI: "cloud-based-services",
    text: "Service",
    serviceName: "Cloud-based services",
    link: "Cloud-based-services",
    serviceItems: [],
    serviceDescription:
      "All out data services are cloud-based, developed under an open-source license. This allows for a more efficient, secure and flexible use of our products and services.",
    serviceImg: CloudServices,
  },
];
