import React from "react";
import { useLocation } from "react-router-dom";

export function useScrollToTop() {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "") {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 5);
    }
  }, [location]);

  return null;
}
