import { css } from "styled-components/macro";
import { ProjectPalette, ProjectTypography } from "app/theme/index";

export const footerCss = css`
  left: 0;
  bottom: 0;
  width: 100vw;
  /* height: 35.9rem; */
  height: 100%;

  padding: 3rem 5rem;
  padding-top: 4rem;
  position: relative;
  background-color: ${ProjectPalette.primary.dark};
`;

export const footerContainerCss = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const footerLogoCss = css`
  grid-area: 1 / 1 / 2 / 2;
  height: 17rem;
  padding-top: 1rem;

  > svg {
    transform: scale(1.3);
  }
`;

export const footerLinkCss = css`
  grid-area: 1 / 2 / 2 / 3;
  padding-left: 2rem;
  border-left: 1px solid ${ProjectPalette.primary.main};
  display: flex;
  flex-direction: column;
  height: 20rem;
  height: 100%;

  a {
    text-decoration: none;
    margin-bottom: 1rem;
    font-size: ${ProjectTypography.overline?.fontSize};
    font-weight: ${ProjectTypography.overline?.fontWeight};
    line-height: ${ProjectTypography.overline?.lineHeight};

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    color: ${ProjectPalette.common.white};
    transition: 0.3s all;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 500px) {
    align-self: center;
    margin-bottom: 5rem;
    position: relative;
    margin-top: -16rem;
  }
`;

export const footerAddressCss = css`
  grid-area: 1 / 3 / 2 / 6;
  border-left: 1px solid ${ProjectPalette.primary.main};
  padding-left: 2rem;
  height: 19rem;
  height: 100%;

  font-size: ${ProjectTypography.overline?.fontSize};
  font-weight: ${ProjectTypography.overline?.fontWeight};
  color: ${ProjectPalette.common.white};
  line-height: ${ProjectTypography.overline?.lineHeight};

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  > p:first-of-type {
    margin-top: 0;

    > a {
      text-decoration: none;
      color: ${ProjectPalette.common.white};
    }
  }

  @media (max-width: 500px) {
    margin-left: 115px;
    align-self: center;
    margin-bottom: 3rem;
  }
`;

export const footerAddressTextCss = css`
  margin-bottom: 1.5rem;
`;

export const footerAddressLinksCss = css`
  margin-top: 3rem;

  @media (max-width: 425px) {
    width: 100%;
    display: flex;
  }

  a {
    margin-right: 3rem;
  }
`;

export const footerPrivacyCss = css`
  grid-area: 2 / 1 / 3 / 6;
  height: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${ProjectPalette.primary.main};
  position: relative;
  color: ${ProjectPalette.common.white};
  font-size: ${ProjectTypography.overline?.fontSize};
  font-weight: ${ProjectTypography.overline?.fontWeight};
  color: ${ProjectPalette.common.white};
  margin-top: 3rem;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  a {
    font-size: ${ProjectTypography.overline?.fontSize};
    font-weight: ${ProjectTypography.overline?.fontWeight};
    color: ${ProjectPalette.common.white};
    line-height: ${ProjectTypography.overline?.lineHeight};
    text-decoration: none;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  > p {
    top: 2rem;
    display: flex;
    align-items: center;

    > a {
      color: ${ProjectPalette.common.white};
      font-weight: ${ProjectTypography.body2?.fontWeight};
      line-height: ${ProjectTypography.body2?.lineHeight};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  > button {
    background-color: ${ProjectPalette.common.white};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    margin-top: 1rem;

    &:hover {
      opacity: 0.8;
      background-color: ${ProjectPalette.common.white};
    }

    @media (max-width: 500px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 500px) {
    display: block;
    margin-top: 3.5rem;
  }
`;
