// cc:application base#;application routes

import React from "react";
import { useGA } from "app/hooks/useGA";
import { Route, Routes } from "react-router-dom";
import { useScrollToTop } from "app/hooks/useScrollToTop";
import { InPageLoader } from "app/modules/common/page-loader";

const LandingModule = React.lazy(() => import("app/modules/landing-module"));
const ContactModule = React.lazy(() => import("app/modules/Contact-module"));
const PortfolioModule = React.lazy(
  () => import("app/modules/Portfolio-module")
);
const DemoModule = React.lazy(() => import("app/modules/demo-module"));
const PortfolioDetailModule = React.lazy(
  () => import("app/modules/Portfolio-detail")
);
const InsightModule = React.lazy(() => import("app/modules/Insights-module"));
const InsightDetailModule = React.lazy(
  () => import("app/modules/insight-detail-module")
);
const MissionModule = React.lazy(() => import("app/modules/Mission-module"));
const NoMatchModule = React.lazy(
  () => import("app/modules/common/no-match-page")
);
const ServicesModule = React.lazy(() => import("app/modules/services-module"));

const WorkWithUsModule = React.lazy(() => import("app/modules/work-with-us"));
const TeamModule = React.lazy(() => import("app/modules/team-module"));
const JobDetailModule = React.lazy(
  () => import("app/modules/job-detail-module")
);

const ServiceDetailModule = React.lazy(
  () => import("app/modules/service-detail-module")
);

export function MainRoutes() {
  useGA();
  useScrollToTop();

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <LandingModule />
            </React.Suspense>
          }
        />
        <Route
          path="/portfolio"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <PortfolioModule />
            </React.Suspense>
          }
        />
        <Route
          path="/portfolio/:id"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <PortfolioDetailModule />
            </React.Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <ContactModule />
            </React.Suspense>
          }
        />
        <Route
          path="/components-demo"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <DemoModule />
            </React.Suspense>
          }
        />
        <Route
          path="/insights"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <InsightModule />
            </React.Suspense>
          }
        />
        <Route
          path="/insights/:id"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <InsightDetailModule />
            </React.Suspense>
          }
        />
        <Route
          path="/mission"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <MissionModule />
            </React.Suspense>
          }
        />
        <Route
          path="/services/:id"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <ServiceDetailModule />
            </React.Suspense>
          }
        />
        <Route
          path="/services"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <ServicesModule />
            </React.Suspense>
          }
        />
        <Route
          path="/team/work-with-us"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <WorkWithUsModule />
            </React.Suspense>
          }
        />
        <Route
          path="/team"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <TeamModule />
            </React.Suspense>
          }
        />
        <Route
          path="/team/jobs/:id"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <JobDetailModule />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense fallback={<InPageLoader />}>
              <NoMatchModule />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
