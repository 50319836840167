// cc:application base#;application providers
import React from "react";
import theme from "app/theme";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Helmet from "react-helmet";

type ProviderProps = {
  children?: React.ReactNode;
};

function Providers(props: ProviderProps) {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box id="app-container" css={"padding-top: 64px;"}>
          <Router>{props.children}</Router>
        </Box>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default Providers;
