import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;

}

:root {
  min-height: 100vh;
  position: relative;
  @media (max-width: 2560px) {
    font-size: 62.5%;
  }
  @media (max-width: 1440px) {
    font-size: 62.5%;
  }
  @media (max-width: 1024px) {
    font-size: 56.25%;
  }
  /* @media (max-width: 768px) {
    font-size: 50%;
  } */
  /* @media (max-width: 425px) {
    font-size: 50%;
  }
  @media (max-width: 375px) {
    font-size: 45%;
  } */
}
`;
