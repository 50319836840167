// cc:application base#;application index

import React from "react";
import Providers from "app/Providers";
import { MainRoutes } from "app/Routes";
import Footer from "app/components/footer";
import { Appbar } from "app/components/appbar";
import { StoreProvider } from "easy-peasy";
import store from "app/state/store";
import { GlobalStyle } from "app/theme/GlobalStyle";

export function App() {
  return (
    <StoreProvider store={store}>
      <Providers>
        <GlobalStyle />
        <Appbar />
        <MainRoutes />
        <Footer />
      </Providers>
    </StoreProvider>
  );
}
